function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function deleteCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const getCookieValue = (name) => document.cookie.split('; ').find((row) => row.startsWith(`${name}=`))?.split('=')[1];

export const TOKEN_KEY = '@flagTOKEN';
export const isAuthenticated = () => getCookieValue('auth');
export const getToken = () => getCookieValue('auth');
export const login = (data) => setCookie('auth', data.token, 365);
export const updateToken = (data) => setCookie('auth', data.token, 365);
export const logout = () => deleteCookie('auth');
