export const QuestionExtraPointDefense = [
  {
    id: 'extra_points',
    label: 'Extra point de quantos pontos?',
    field: 'extra_points',
    visible: true,
    actions: [
      { label: '1', value: 1, next: 'type' },
      { label: '2', value: 2, next: 'type' },
    ],
  },
  {
    id: 'type',
    label: 'Como foi a jogada?',
    field: 'type',
    visible: false,
    reset: ['fault'],
    actions: [
      { label: 'Passe', value: 'pass', next: 'play_result' },
      { label: 'Corrida', value: 'run', next: 'play_result_running' },
      {
        label: 'Falta bola morta', value: 'fault_dead_ball', next: 'fault_team', set_values: ['fault=true'],
      },
      { label: 'Bad Snap', value: 'bad_snap', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'play_result',
      },
    ],
  },
  {
    id: 'play_result',
    label: 'Qual o resultado da jogada?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'tackle_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'play_result_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'fault' },
      { label: 'Conversão', value: 'touchdown', next: 'fault' },
    ],
  },
  {
    id: 'play_result_running',
    label: 'Qual o resultado do ataque?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'tackle_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'running_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'fault' },
      {
        label: 'Conversão', value: 'touchdown', next: 'fault', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'running_incomplete',
    label: 'Qual o resultado da tentativa?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'sack_defensive_player_end_play' },
    ],
  },
  {
    id: 'play_result_incomplete',
    label: 'Como acabou a jogada?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Tip', value: 'tip', next: 'tip_defensive_player_end_play' },
      { label: 'Passe errado', value: 'wrong_pass', next: 'fault' },
      { label: 'Interceptação', value: 'intercept', next: 'is_touchdown_intercept' },
      { label: 'Bola Fora', value: 'out', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: 'Incompleto', next: 'fault',
      },
    ],
  },
  {
    id: 'tackle_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fault' },
    ],
  },
  {
    id: 'sack_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fault' },
    ],
  },
  {
    id: 'tip_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fault' },
    ],
  },
  {
    id: 'interceptor',
    label: 'Quem finalizou a jogada?',
    field: 'interceptor',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'is_touchdown_intercept' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'is_touchdown_intercept',
      },
    ],
  },
  {
    id: 'is_touchdown_intercept',
    label: 'Foi conversão da defesa?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault' },
      { label: 'Não', value: false, next: 'fault' },
    ],
  },
  {
    id: 'fault',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_team',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      { label: 'Ataque', value: 'offense', next: 'fault_repeat_down' },
      { label: 'Defesa', value: 'defense', next: 'fault_player' },
    ],
  },
  {
    id: 'fault_player',
    label: 'Qual atleta fez a falta?',
    field: 'fault_player',
    visible: false,
    actions: [
      { type: 'athletes', team: 'offense', next: 'fault_repeat_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_repeat_down',
      },
    ],
  },
  {
    id: 'fault_repeat_down',
    label: 'Repete a descida?',
    field: 'fault_repeat_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'ignore_play' },
      { label: 'Não', value: false, next: 'ignore_play' },
    ],
  },
  {
    id: 'ignore_play',
    label: 'Ignorar pontuação e estatísticas desta jogada?',
    field: 'ignore_play',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'save' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
];
