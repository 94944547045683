export const QuestionDefense = [
  {
    id: 'is_try_td',
    label: 'Qual é a tentativa?',
    field: 'is_try_td',
    visible: true,
    actions: [
      { label: 'Meio', value: false, next: 'fst_down' },
      { label: 'Touchdown', value: true, next: 'down' },
    ],
  },
  {
    id: 'down',
    label: 'Qual é a descida?',
    field: 'down',
    visible: false,
    actions: [
      { label: '1ª', value: 1, next: 'type' },
      { label: '2ª', value: 2, next: 'type' },
      { label: '3ª', value: 3, next: 'type' },
      { label: '4ª', value: 4, next: 'type' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'type',
      },
    ],
  },
  {
    id: 'fst_down',
    label: 'Qual é a descida?',
    field: 'down',
    visible: false,
    actions: [
      { label: '1ª', value: 1, next: 'fst_type' },
      { label: '2ª', value: 2, next: 'fst_type' },
      { label: '3ª', value: 3, next: 'fst_type' },
      { label: '4ª', value: 4, next: 'fst_type' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_type',
      },
    ],
  },
  {
    id: 'type',
    label: 'Como foi a jogada?',
    field: 'type',
    visible: false,
    reset: ['fault'],
    actions: [
      { label: 'Passe', value: 'pass', next: 'play_result' },
      { label: 'Corrida', value: 'run', next: 'play_result_running' },
      {
        label: 'Falta bola morta', value: 'fault_dead_ball', next: 'fault_team', set_values: ['fault=true'],
      },
      { label: 'Bad Snap', value: 'bad_snap', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'play_result',
      },
    ],
  },
  {
    id: 'fst_type',
    label: 'Como foi a jogada?',
    field: 'type',
    visible: false,
    reset: ['fault'],
    actions: [
      { label: 'Passe', value: 'pass', next: 'fst_play_result' },
      { label: 'Corrida', value: 'run', next: 'fst_play_result_running' },
      {
        label: 'Falta bola morta', value: 'fault_dead_ball', next: 'fault_team', set_values: ['fault=true'],
      },
      { label: 'Bad Snap', value: 'bad_snap', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: '', next: 'play_result',
      },
    ],
  },
  {
    id: 'play_result',
    label: 'Qual o resultado da jogada?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'tackle_defensive_player_end_play' },
      { label: 'Sack', value: 'sack', next: 'sack_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'play_result_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'is_touchdown' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'given_yards_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'play_result_running',
    label: 'Qual o resultado da jogada?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'tackle_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'running_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'fault' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'given_yards_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'fst_play_result_running',
    label: 'Qual o resultado da jogada?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'fst_tackle_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'running_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'is_first_down_out_field' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'given_yards_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'running_incomplete',
    label: 'Qual o resultado da tentativa?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'sack_defensive_player_end_play' },
    ],
  },
  {
    id: 'fst_play_result',
    label: 'Qual o resultado da jogada?',
    field: 'play_result',
    visible: false,
    reset: ['is_touchdown'],
    actions: [
      { label: 'Tackle', value: 'tackle', next: 'fst_tackle_defensive_player_end_play' },
      { label: 'Sack', value: 'sack', next: 'sack_defensive_player_end_play' },
      { label: 'Incompleto', value: 'incomplete', next: 'play_result_incomplete' },
      { label: 'Saída de campo', value: 'out_field', next: 'is_first_down_out_field' },
      {
        label: 'Touchdown', value: 'touchdown', next: 'given_yards_td', set_values: ['is_touchdown=true'],
      },
    ],
  },
  {
    id: 'play_result_incomplete',
    label: 'Como acabou a jogada?',
    field: 'play_result',
    visible: false,
    actions: [
      { label: 'Drop', value: 'drop', next: 'fault' },
      { label: 'Tip', value: 'tip', next: 'tip_defensive_player_end_play' },
      { label: 'Passe errado', value: 'wrong_pass', next: 'fault' },
      { label: 'Interceptação', value: 'intercept', next: 'interceptor' },
      { label: 'Bola fora', value: 'out', next: 'fault' },
      { label: 'Sack', value: 'sack', next: 'sack_defensive_player_end_play' },
      {
        label: 'Pular pergunta', type: 'skip', value: 'incompleto', next: 'fault',
      },
    ],
  },
  {
    id: 'tackle_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'given_yards' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'given_yards',
      },
    ],
  },
  {
    id: 'fst_tackle_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fst_tackle_is_first_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fst_tackle_is_first_down',
      },
    ],
  },
  {
    id: 'fst_tackle_is_first_down',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'given_yards' },
      { label: 'Não', value: false, next: 'given_yards' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'given_yards',
      },
    ],
  },
  {
    id: 'sack_defensive_player_end_play',
    label: 'Quem fez o sack?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'is_safety' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'is_safety',
      },
    ],
  },
  {
    id: 'is_safety',
    label: 'Foi safety?',
    field: 'is_safety',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault' },
      { label: 'Não', value: false, next: 'yards' },
    ],
  },
  {
    id: 'tip_defensive_player_end_play',
    label: 'Quem finalizou a jogada?',
    field: 'defensive_player_end_play',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault',
      },
    ],
  },
  {
    id: 'interceptor',
    label: 'Quem fez a interceptação?',
    field: 'interceptor',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'is_touchdown_intercept' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'is_touchdown_intercept',
      },
    ],
  },
  {
    id: 'is_touchdown_intercept',
    label: 'Foi touchdown da defesa?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_td_intercept' },
      { label: 'Não', value: false, next: 'is_first_down_intercept' },
    ],
  },
  {
    id: 'is_first_down_intercept',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'yards_intercept' },
      { label: 'Não', value: false, next: 'yards_intercept' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'given_yards',
      },
    ],
  },
  {
    id: 'is_first_down',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'is_touchdown' },
      { label: 'Não', value: false, next: 'yards' },
    ],
  },
  {
    id: 'is_first_down_out_field',
    label: 'Foi conversão para o meio?',
    field: 'is_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'given_yards' },
      { label: 'Não', value: false, next: 'given_yards' },
    ],
  },
  {
    id: 'is_touchdown',
    label: 'Foi touchdown?',
    field: 'is_touchdown',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault' },
      { label: 'Não', value: false, next: 'given_yards_td' },
    ],
  },
  {
    id: 'given_yards',
    label: 'Quantas jardas foram cedidas?',
    field: 'given_yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault',
      },
    ],
    type: 'yards',
  },
  {
    id: 'given_yards_td',
    label: 'Quantas jardas foram cedidas?',
    field: 'given_yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault_td' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_td',
      },
    ],
    type: 'yards',
  },
  {
    id: 'yards_intercept',
    label: 'Quantas jardas a defesa conquistou?',
    field: 'yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault' },
    ],
    type: 'yards',
  },
  {
    id: 'yards',
    label: 'Quantas jardas a defesa conquistou?',
    field: 'yards',
    visible: false,
    actions: [
      { type: 'number', value: '', next: 'fault' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault',
      },
    ],
    type: 'yards',
  },
  {
    id: 'fault',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_td',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team_td' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_td_intercept',
    label: 'Teve falta na jogada?',
    field: 'fault',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'fault_team_td_intercept' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
  {
    id: 'fault_team',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      { label: 'Ataque', value: 'offense', next: 'fault_yards_offense' },
      { label: 'Defesa', value: 'defense', next: 'fault_player' },
    ],
  },
  {
    id: 'fault_team_td',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      {
        label: 'Ataque', value: 'offense', next: 'fault_yards_offense', set_values: ['play_result=touchdown', 'is_touchdown=true'],
      },
      {
        label: 'Defesa', value: 'defense', next: 'fault_player', set_values: ['play_result=fault', 'is_touchdown=false'],
      },
    ],
  },
  {
    id: 'fault_team_td_intercept',
    label: 'Qual time fez a falta?',
    field: 'fault_team',
    visible: false,
    actions: [
      {
        label: 'Ataque', value: 'offense', next: 'fault_yards_offense', set_values: ['play_result=fault', 'is_touchdown=false'],
      },
      {
        label: 'Defesa', value: 'defense', next: 'fault_player', set_values: ['play_result=touchdown', 'is_touchdown=true'],
      },
    ],
  },
  {
    id: 'fault_player',
    label: 'Qual atleta fez a falta?',
    field: 'fault_player',
    visible: false,
    actions: [
      { type: 'athletes', team: 'defense', next: 'fault_yards_defense' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_yards_defense',
      },
    ],
  },
  {
    id: 'fault_yards_offense',
    label: 'Quantas jardas de penalidade?',
    field: 'fault_yards',
    visible: false,
    actions: [
      { label: '0', value: 0, next: 'fault_repeat_down' },
      { label: '5', value: 5, next: 'fault_repeat_down' },
      { label: '10', value: 10, next: 'fault_repeat_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_repeat_down',
      },
    ],
  },
  {
    id: 'fault_yards_defense',
    label: 'Quantas jardas de penalidade?',
    field: 'fault_yards',
    visible: false,
    actions: [
      { label: '0', value: 0, next: 'fault_auto_first_down' },
      { label: '5', value: 5, next: 'fault_auto_first_down' },
      { label: '10', value: 10, next: 'fault_auto_first_down' },
      {
        label: 'Pular pergunta', type: 'skip', value: undefined, next: 'fault_auto_first_down',
      },
    ],
  },
  {
    id: 'fault_auto_first_down',
    label: 'Primeira descida automática?',
    field: 'fault_auto_first_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'ignore_play' },
      { label: 'Não', value: false, next: 'fault_repeat_down' },
    ],
  },
  {
    id: 'fault_repeat_down',
    label: 'Repete a descida?',
    field: 'fault_repeat_down',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'ignore_play' },
      { label: 'Não', value: false, next: 'ignore_play' },
    ],
  },
  {
    id: 'ignore_play',
    label: 'Ignorar pontuação e estatísticas desta jogada?',
    field: 'ignore_play',
    visible: false,
    actions: [
      { label: 'Sim', value: true, next: 'save' },
      { label: 'Não', value: false, next: 'save' },
    ],
  },
];
