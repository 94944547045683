import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import en from './locales/en.json';
import es from './locales/es.json';

const token = Cookies.get('auth');
const lang = token ? jwtDecode(token).lang : 'pt';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en,
      es,
    },
    lng: lang,
  });

export default i18n;
