import { apiSlice } from './apiSlice';

const matchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchMatchesByType: builder.query({
      query: ({ team, type }) => `/api/team/${team}/${type}`,
      providesTags: (result, error, type) => [{ type: 'Matches', id: type }],
    }),
    fetchUpcomingMatches: builder.query({
      query: ({ team }) => `/api/team/${team}/upcoming`,
      providesTags: (result, error, type) => [{ type: 'Matches', id: type }],
    }),
    fetchTournaments: builder.query({
      query: (team) => `/api/team/${team}/tournaments`,
      providesTags: () => [{ type: 'Tournaments', id: 'all' }],
    }),
    fetchMatch: builder.query({
      query: ({ team, match }) => `/api/team/${team}/match/${match}`,
      providesTags: (result, error, match) => [{ type: 'Matches', id: match }],
    }),
    fetchPlays: builder.query({
      query: ({ team, match }) => `/api/team/${team}/match/${match}/plays`,
      providesTags: (result, error, match) => [{ type: 'Plays', id: match }],
    }),
    fetchMatchStats: builder.query({
      query: ({ team, match }) => `/api/team/${team}/match/${match}/stats`,
      providesTags: (result, error, match) => [
        { type: 'MatchStats', id: match },
        { type: 'Matches', id: match },
      ],
    }),
    createMatch: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/match`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Tournaments', id: 'all' }],
    }),
    updateMatch: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/match`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'Matches', id: body._id },
        { type: 'Tournaments', id: 'all' },
      ],
    }),
    deleteMatch: builder.mutation({
      query: ({ team, body }) => ({
        url: `/api/team/${team}/match`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: 'Matches', id: 'all' }],
    }),
    createPlay: builder.mutation({
      query: ({ team, match, body }) => ({
        url: `/api/team/${team}/match/${match}/play`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, match) => [{ type: 'Matches', id: match }, { type: 'Plays', id: match }],
    }),
    updatePlay: builder.mutation({
      query: ({
        team, match, play, body,
      }) => ({
        url: `/api/team/${team}/match/${match}/play/${play}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, match) => [{ type: 'Matches', id: match }, { type: 'Plays', id: match }],
    }),
    deletePlay: builder.mutation({
      query: ({
        team, match, play, body,
      }) => ({
        url: `/api/team/${team}/match/${match}/play/${play}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, match) => [{ type: 'Matches', id: match }, { type: 'Plays', id: match }],
    }),
    updateLive: builder.mutation({
      query: ({ team, match, body }) => ({
        url: `/api/team/${team}/match/${match}/update-live`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, match) => [
        { type: 'Matches', id: match },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchMatchesByTypeQuery,
  useFetchUpcomingMatchesQuery,
  useFetchMatchQuery,
  useFetchMatchStatsQuery,
  useFetchPlaysQuery,
  useFetchTournamentsQuery,
  useCreateMatchMutation,
  useUpdateMatchMutation,
  useDeleteMatchMutation,
  useCreatePlayMutation,
  useUpdatePlayMutation,
  useDeletePlayMutation,
  useUpdateLiveMutation,
} = matchSlice;
