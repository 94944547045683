import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardAthlete,
  IconPlus,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Header } from 'shared/layouts';
import { useFetchAthletesQuery } from 'shared/services';

export function RegisterAthleteList() {
  const team = useContext(TeamContext);
  const athletes = useFetchAthletesQuery(team._id);
  const { t } = useTranslation();

  return (
    <>
      <Header title="FlagStats" />
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: '58px',
            display: 'flex',
            flexDirection: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography component="h1" variant="h6">
                {t('Atletas')}
              </Typography>
              <Typography component="h2" variant="caption">
                {t('Cadastre atletas aqui ou faça isso depois na área')}
                {' '}
                <strong>{t('Atletas')}</strong>
                .
              </Typography>
            </Grid>

            <Grid item>
              <Button
                href="/register/athletes/form"
                fullWidth
                variant="outlined"
              >
                <IconPlus />
                <Typography variant="caption">
                  {t('Adicionar nova atleta')}
                </Typography>
              </Button>
            </Grid>

            <Grid item>
              <Box
                sx={{
                  // display: 'none',
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {athletes.isSuccess && athletes
                  && athletes.data.map(
                    (athlete) => <CardAthlete key={athlete._id} athlete={athlete} />,
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
        }}
      >
        <Button
          href="/register/success"
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('Continuar')}
        </Button>
      </Box>
    </>
  );
}

export default RegisterAthleteList;
